import React, { Component, useState, useEffect } from 'react';
import { motion } from 'framer-motion'
import './Square.scss';

export default function Square(props) {
    const { logo, nologo, role, est, supertitle, synopsis, link, nolink, contact } = props;
    return (
        <div className={'Square'}>

            <div className={'Square-logo'}>
                <div className={'Square-header'}>
                    {est && <span>Est. {est} </span>}
                    {role && <span>{role}</span>}
                    {supertitle && <span>{supertitle}</span>}
                </div>
                {logo && <img src={logo} />}
                {nologo && <div className={'nologo'}>{nologo}</div>}
            </div>
            <div className={'Square-synopsis'}>
                {synopsis}
            </div>
            <div className={'Square-link'}>
                {
                    nolink ?
                        <span>{nolink}</span>
                        :
                        link && <a href={`https://www.${link}`} target={'_blank'}>{link}</a>

                }

                {
                    contact && <span>Contact</span>
                }

            </div>
        </div>
    )
}