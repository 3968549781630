import React, { Component, useState, useEffect } from 'react';
import Square from './components/Square/Square';
import './App.scss';
import Contact from './components/Contact/Contact';

export default function App() {

	const [ventures, setVentures] = useState(true);
	const [contact, setContact] = useState(false);

	return (
		<div className={'App'}>
			<div className={'Header'}>
				<div className={'Header-about'}>
					<h1>Ian Coyle</h1>
					<p>Pacific Northwest creative entrepreneur and independent investor.</p>
				</div>
				<div className={'Header-nav'} onClick={() => { setVentures(!ventures) }}>
					{
						ventures === true ? <span>👋</span> : <span>👋</span>
					}
				</div>
			</div>

			<div className={'Content'}>

				{
					ventures === true ?

						<div className={'Squares'}>
							<Square
								logo={require('./assets/logo-enjoy.svg')}
								synopsis={'Enjoy the Weather is an independent creative and digital experience agency located in Portland, Oregon. Clients include the likes of Jordan Brand, Sonos, Nike, Converse, and more.'}
								link={'enjoytheweather.com'}
								role={'Principal'}
								est={'2016'}
							/>

							<Square
								logo={require('./assets/logo-rally.svg')}
								synopsis={'We believe in motorcycles as a vehicle for human connection. That\'s why we\'ve built Rally Moto Club and our mission is simple — we\'re here to connect and inspire riders online and in real life.'}
								link={'letsrally.io'}
								role={'CXO'}
								est={'2021'}
							/>

							<Square
								logo={require('./assets/logo-forecast.svg')}
								synopsis={'Forecast Worldwide is a new special projects venture launched in 2022, focus on Web3, fashion, sustainability and EV market sectors. Follow us to find out more.'}
								link={'thisisforecast.com'}
								role={'Principal'}
								est={'2022'}
							/>

							<Square
								logo={require('./assets/logo-salt.svg')}
								synopsis={'An automotive studio based on the love of vintage cars, motorcycles, and the lure of dust trails over salt flats. We find, design, and restore classic project vehicles.'}
								link={'saltvehicleworks.com'}
								role={'Founder'}
								est={'2020'}
							/>

							<Square
								logo={require('./assets/logo-weekends.svg')}
								synopsis={'Opening 2022 in Northeast Portland, Weekends is a european-style cafe for your Sunday (or any day) ride. Stop by soon.'}
								nolink={'Opening Soon'}
								role={'Investor'}
								est={'2022'}
							/>

							<Square
								logo={require('./assets/logo-burntown.svg')}
								synopsis={'A Personal Training Studio that specializes in creating workout programs and routines that will challenge your Core, Cardio, Power, Strength and Stability.'}
								link={'burntown.com'}
								role={'Founding Investor'}
								est={'2018'}
							/>

							{/* <Square
								logo={require('./assets/logo-driver.svg')}
								synopsis={'A daily publication for those who love four wheels and rare automobiles. '}
								link={'thedriverdaily.com'}
								role={'Curator'}
								est={'2020'}
							/>

							<Square
								nologo={'👋'}
								header={'Connect'}
								synopsis={'Say hi. Collaborate. Inquiries.'}
								contact={true}
								supertitle={'Contact'}
							/> */}

						</div>

						:
						<div className={'About'}>

							<div className={'About-row'}>
								<div className={'About-row-title'}>About</div>
								<div className={'About-row-copy'}>
									<p>For over 20 years, Ian has led creative teams to fuse design and technology for high-profile initiatives. Notable clients include Apple, Jordan Brand, Nike, Sonos, Google and Converse.</p>
								</div>
							</div>

							<div className={'About-row'}>
								<div className={'About-row-title'}>Ventures</div>
								<div className={'About-row-copy'}>
									<p>He also invests in small businesses, passion projects, and new startups looking for initial growth capital or collaboration.</p>
								</div>
							</div>

							<div className={'About-row'}>
								<div className={'About-row-title'}>Projects</div>
								<div className={'About-row-copy'}>
									<p>In his spare time, Ian collects and rebuilds vintage cars and motorcycles. He's always on the lookout for a new project vehicle. </p>
								</div>
							</div>

							<div className={'About-row'}>
								<div className={'About-row-title'}>Connect</div>
								<div className={'About-row-copy'}>
									<p>Say hi. Collaborate. Inquiries. <br />
										@<span onClick={() => { setContact(true) }}>iancoyle</span>
									</p>

								</div>
							</div>


						</div>

				}


				{
					contact && <Contact onClose={() => {
						setContact(false)
					}} />
				}
			</div>

		</div >
	);

}


